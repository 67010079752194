import React, { useState } from 'react';

import {
  InputLabel,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import { useDropzone } from "react-dropzone";

import { connect } from "react-redux";

import { scaledImageSource } from "../../../../../../components/ScaledImage";

import FeathersHelper from '../../../../../../helpers/feathers';

import axios from "axios";
import { uploadFile } from '../../../../../../helpers/s3';

const useStyles = makeStyles((theme) => ({
  imageDrop: {
    marginTop: 20,
    marginBottom: 10,
    backgroundColor: "#FAFAFA",
    border: "1px solid #EEEEEF",
    overflow: "hidden",
    position: 'relative'
  },
  fileDropInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      color: "#BCBCBF",
      fontSize: '0.75rem',
      textAlign: 'center',
      maxWidth: '90%'
    },
  },
  imageDropInner: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "& p": {
      color: "#BCBCBF",
      fontSize: '0.75rem',
      textAlign: 'center',
      width: 90,
      maxWidth: '90%'
    },
  },
}));

const ImageField = props => {

  const [uploading, setUploading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [signedUrl, setSignedUrl] = useState("")

  const classes = useStyles();

  const onDrop = (acceptedFiles) => {

    setUploading(true);

    const file = acceptedFiles[0];

    uploadFile(file, props.definition.width, props.definition.height, props.definition.fit||"inside", props.definition.public).then(r => {
      setUploading(false);
      console.log(r)
      props.onChange({ target: { value: r.key } })
      setSignedUrl(r.signedResizedURL)

      console.log(`Key is ${r.key}`)
    }).catch(e => {
      console.log("Upload error", e)
      setUploading(false);
    })


  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const value = props.value();

  return (
    <React.Fragment>
      <InputLabel className={["MuiInputLabel-shrink", props.classes.label]}>
        __{props.definition.label}
      </InputLabel>
      <div
        className={classes.imageDrop}
        style={{
          paddingTop: `${(1 / props.definition.aspectRatio) * 100}%`,
          position: "relative",
          borderRadius: props.definition.borderRadius,
        }}
        {...getRootProps()}
      >
        <div
          className={classes.imageDropInner}
          style={
            value ? {
              backgroundImage: `url(${value.indexOf('http') != -1 ? value : signedUrl})`,
            } : {}
          }
        >

          <input {...getInputProps()} />
          <p>signed: {signedUrl}</p>
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : value ? null : uploading ? (
            <p>Subiendo... ({uploadPercent}%)</p>
          ) : (
            <p>Drag 'n' drop or select files</p>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    selectedApp: state.data.apps.selected,
  }),
  null
)(ImageField);